<template>
  <Wrapper>
    <router-view></router-view>
<!--    <Autosave />-->
  </Wrapper>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    Wrapper: () => import('@/components/layouts/NavWrapper'),
    // Autosave: () => import('@/components/modals/AutosaveModal'),
  }
}
</script>

<style scoped>

</style>
